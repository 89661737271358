<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';
import { pascalize } from 'humps';
import { useForm } from 'vee-validate';
import { computed, ref, toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { array, object, string } from 'yup';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import type { RejectionTags, AasmStateEvent, KycDocument } from '@/api/kycDocument';
import { useUpdateKycDocument } from '@/api/kycDocument/mutations';
import { groupLoanApplicationKey } from '@/api/queryKeys';
import { tagsKey } from '@/api/queryKeys/tags';
import type { ResourceType } from '@/api/tags';
import { useCreateTags } from '@/api/tags/mutations';
import { useFetchTags } from '@/api/tags/queries';
import BaseBadge from '@/components/base-badge.vue';
import BaseButton from '@/components/base-button.vue';
import BaseImageWithControls from '@/components/base-image-with-controls.vue';
import BaseModal from '@/components/base-modal.vue';
import GroupLoanApplicationsMemberLoanKycAddressProof
  from '@/components/reviews/group-loan-applications-member-loan-kyc-address-proof.vue';
import UploadKycDocumentFileModal from '@/components/reviews/upload-kyc-document-file-modal.vue';
import VeeValidateSelect from '@/components/vee-validate/vee-validate-select.vue';
import useStrictInject from '@/composables/useStrictInject';
import useUIStore from '@/stores/ui';
import { kycDocumentsRejectionTagsKey } from '@/utils/keys';
import { isAddressProof } from '@/utils/kycDocumentsGuards';

const { t } = useI18n();

const uiStore = useUIStore();

interface Props {
  kycDocument: KycDocument;
  groupMemberLoanApplication: GroupMemberLoanApplication;
}
const props = defineProps<Props>();

const KYC_DOCUMENTS_REJECTION_TAGS = useStrictInject<RejectionTags>(kycDocumentsRejectionTagsKey);
const rejectionTags = computed(() => KYC_DOCUMENTS_REJECTION_TAGS[props.kycDocument.kind]);
const rejectionTagOptions = computed(() => rejectionTags.value.map(
  (rejectionTag) => ({
    label: t(`kycDocument.rejectionTags.${props.kycDocument.kind}.${rejectionTag}`),
    value: rejectionTag,
  }),
));

const documentableId = computed(() => props.groupMemberLoanApplication.id);

const queryClient = useQueryClient();

const showRejectModal = ref(false);

const resourceId = computed(() => props.kycDocument.id);
const resourceType = computed(() => pascalize(props.kycDocument.kind) as ResourceType);

const rejectionTagsQ = useFetchTags({ context: 'rejection_tags', resourceId, resourceType });

interface FormValues {
  rejectionTags?: string[];
}

const validationSchema = object({
  rejectionTags: array().of(
    string().oneOf(rejectionTags.value, 'Las razones elegidas no corresponden a las existentes').required(),
  ).min(1, 'Tienes que elegir al menos una razón'),
});
const { values, handleSubmit } = useForm<FormValues>({
  validationSchema,
  initialValues: { rejectionTags: [] },
});

const createTagsMutation = useCreateTags({
  onError: (e) => {
    uiStore.toast({
      message: e.response?.data?.detail || 'Hubo un error al crear los tags',
      type: 'error',
      position: 'top',
    });
  },
});

const updateDocumentableKycDocumentMutation = useUpdateKycDocument({
  documentableId,
  documentableType: 'GroupMemberLoanApplication',
  onSuccess() {
    showRejectModal.value = false;
    queryClient.invalidateQueries({
      queryKey: groupLoanApplicationKey(toRef(props.groupMemberLoanApplication, 'groupLoanApplicationId')),
    });
    queryClient.invalidateQueries({
      queryKey: tagsKey({
        resourceType: toRef(pascalize(props.kycDocument.kind) as ResourceType),
        resourceId: toRef(props.kycDocument, 'id'),
        context: toRef<'rejection_tags'>('rejection_tags'),
      }),
    });
  },
});

function reviewDocument(button: 'approve' | 'reject') {
  let aasmStateEvent : AasmStateEvent;

  if (button === 'approve') {
    aasmStateEvent = props.kycDocument.aasmState === 'approved' ? 'pass_to_review' : 'approve';
  } else {
    aasmStateEvent = props.kycDocument.aasmState === 'rejected' ? 'pass_to_review' : 'reject';
  }

  if (aasmStateEvent === 'reject' && values.rejectionTags?.length) {
    createTagsMutation.mutate({
      context: 'rejection_tags',
      tags: values.rejectionTags,
      resourceId: props.kycDocument.id,
      resourceType: pascalize(props.kycDocument.kind) as ResourceType,
    });
  }

  return updateDocumentableKycDocumentMutation.mutate({
    id: props.kycDocument.id,
    body: {
      aasmStateEvent,
      kycDocumentType: props.kycDocument.kind,
    },
  });
}

const showAmendFileModal = ref(false);

function handleReject() {
  if (props.kycDocument.aasmState === 'rejected') {
    reviewDocument('reject');

    return;
  }

  showRejectModal.value = true;
}

const onSubmit = handleSubmit(() => {
  reviewDocument('reject');
});

</script>

<template>
  <div class="grid grid-cols-2 gap-x-4 overflow-hidden">
    <div
      v-if="!!rejectionTagsQ.data?.length"
      class="col-span-2 flex flex-wrap gap-2 p-2"
    >
      <base-badge
        v-for="tag in rejectionTagsQ.data"
        :key="tag.name"
        size="xs"
        color="red"
      >
        {{ t(`kycDocument.rejectionTags.${props.kycDocument.kind}.${tag.name}`) }}
      </base-badge>
    </div>
    <div class="flex flex-col gap-y-4 py-2">
      <base-image-with-controls
        v-if="kycDocument.fileUrl"
        :src="kycDocument.fileUrl"
        type="application/pdf"
        class="max-h-[60rem] w-full flex-1 rounded-lg object-contain"
      />
      <base-button
        v-if="kycDocument.aasmState === 'rejected'"
        variant="white"
        text="Corregir"
        @click="showAmendFileModal = true"
      />
      <upload-kyc-document-file-modal
        :show="showAmendFileModal"
        :kyc-document="kycDocument"
        :group-loan-application-id="groupMemberLoanApplication.groupLoanApplicationId"
        :documentable-id="groupMemberLoanApplication.id"
        documentable-type="GroupMemberLoanApplication"
        @close="showAmendFileModal = false"
      />
    </div>
    <div class="flex flex-1 flex-col gap-y-4 rounded-lg border-2 p-4">
      <div class="flex flex-1 flex-col">
        <group-loan-applications-member-loan-kyc-address-proof
          v-if="isAddressProof(kycDocument)"
          :address-proof="kycDocument"
        />
        <template v-else>
          <div class="flex flex-1 flex-col items-center">
            <p>Verifica que el documento sea el correcto  </p>
          </div>
        </template>
      </div>
      <div class="flex items-center gap-x-4">
        <base-button
          class="flex-1"
          :text="kycDocument.aasmState === 'rejected' ? 'Rechazado' : 'Rechazar'"
          :variant="kycDocument.aasmState === 'rejected' ? 'red' : 'white'"
          :loading="updateDocumentableKycDocumentMutation.isPending"
          @click="handleReject"
        />
        <base-button
          class="flex-1"
          :text="kycDocument.aasmState === 'approved' ? 'Aprobado' : 'Aprobar'"
          :variant="kycDocument.aasmState === 'approved' ? 'primary' : 'white'"
          :loading="updateDocumentableKycDocumentMutation.isPending"
          @click="reviewDocument('approve')"
        />
      </div>
    </div>
    <base-modal
      :show="showRejectModal"
      title="Rechazar documento"
      @close="showRejectModal = false"
    >
      <template #body>
        <form class="mt-2">
          <vee-validate-select
            label="¿Por qué quieres hacerlo?"
            name="rejectionTags"
            with-undefined-value
            :options="rejectionTagOptions"
            mode="tags"
          />
        </form>
      </template>
      <template #actions>
        <div class="flex items-center gap-x-4">
          <base-button
            class="grow"
            text="Cancelar"
            variant="white"
            @click="showRejectModal = false"
          />
          <base-button
            :disabled="!values.rejectionTags?.length"
            class="grow"
            text="Rechazar"
            variant="red"
            @click="onSubmit"
          />
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import { ArrowDownOnSquareIcon } from '@heroicons/vue/24/outline';
import { Chart, registerables } from 'chart.js';
import { onMounted, computed, ref } from 'vue';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import type { CCLoan } from '@/api/groupMemberLoanApplication/CC';
import { formatDate, toCurrency } from '@/utils/filters';
import htmlToPdf from '@/utils/htmlToPdf';

import ConsolidatedCreditReportLoansTable from './consolidated-credit-report-loans-table.vue';

Chart.register(...registerables);

const props = defineProps<{
  groupMemberLoanApplication: GroupMemberLoanApplication
}>();

const consolidatedCreditReport = computed(() => props.groupMemberLoanApplication.consolidatedCreditReport);

const activeCredits = computed(
  () => (consolidatedCreditReport.value.creditos || []).filter(credito => !credito.fechaCierreCuenta),
);
const totalVigente = computed(() => activeCredits.value.reduce((sum, credito) => sum + Number(credito.saldoActual), 0));
const totalEnAtraso = computed(
  () => activeCredits.value.reduce((sum, credito) => sum + Number(credito.saldoVencido), 0),
);
const totalApproved = computed(
  () => activeCredits.value.reduce((sum, credito) => sum + Number(credito.creditoMaximo), 0),
);
const reportRecentGroupLoansDelinquent = computed(() => (
  props.groupMemberLoanApplication.recentGroupLoans.reduce((acc, loan) => {
    if (loan.saldoVencido > 0) {
      acc.amount += loan.saldoVencido;
      acc.numOfLoans += 1;
    }

    return acc;
  }, { amount: 0, numOfLoans: 0 })
));
const reportRecentIndividualLoansDelinquent = computed(() => (
  props.groupMemberLoanApplication.recentIndividualLoans.reduce((acc, loan) => {
    if (loan.saldoVencido > 0) {
      acc.amount += loan.saldoVencido;
      acc.numOfLoans += 1;
    }

    return acc;
  }, { amount: 0, numOfLoans: 0 })

));

const ficoScore = computed(() => consolidatedCreditReport.value.scores?.[0]?.valor?.toString());

const creditStatusData = {
  labels: ['Total Vigente', 'Total en Atraso'],
  datasets: [
    {
      data: [totalVigente.value, totalEnAtraso.value],
      backgroundColor: ['#32CD32', '#FF0000'],
      hoverBackgroundColor: ['#32CD32', '#FF0000'],
    },
  ],
};

const debtAnalysisData = {
  labels: ['Monto Aprobado', 'Saldo Actual'],
  datasets: [
    {
      data: [totalApproved.value, totalVigente.value],
      backgroundColor: ['#0000FF', '#FFFF00'],
      hoverBackgroundColor: ['#0000FF', '#FFFF00'],
    },
  ],
};

function filterPaymentsByFrequency(loans: CCLoan[], frequency: CCLoan['frecuenciaPagos']) {
  return loans.filter(loan => loan.frecuenciaPagos === frequency);
}

function sumPayments(loans: CCLoan[]) {
  return loans.reduce((acc, loan) => acc + Number(loan.montoPagar), 0);
}

type GroupedLoans = { [key: string]: CCLoan[]; };

const groupedByType = computed(() => {
  const groups: GroupedLoans = (consolidatedCreditReport.value.creditos || []).reduce((acc, loan) => {
    const type = loan.tipoCredito;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(loan);

    return acc;
  }, {} as GroupedLoans);

  return groups;
});

type CCLoanStats = {
  count: number;
  limiteCredito: number;
  creditoMaximo: number;
  saldoActual: number;
  saldoVencido: number;
  pagoSemanal: number;
  pagoQuincenal: number;
  pagoMensual: number;
};

const totalsByType = computed(() => {
  const totals: Record<string, CCLoanStats> = {};
  Object.entries(groupedByType.value).forEach(([type, credits]) => {
    totals[type] = {
      count: credits.length,
      limiteCredito: credits.reduce((sum, { limiteCredito }) => sum + Number(limiteCredito), 0),
      creditoMaximo: credits.reduce((sum, { creditoMaximo }) => sum + Number(creditoMaximo), 0),
      saldoActual: credits.reduce((sum, { saldoActual }) => sum + Number(saldoActual), 0),
      saldoVencido: credits.reduce((sum, { saldoVencido }) => sum + Number(saldoVencido), 0),
      pagoSemanal: sumPayments(filterPaymentsByFrequency(credits, 'S')),
      pagoQuincenal: sumPayments(filterPaymentsByFrequency(credits, 'C')),
      pagoMensual: sumPayments(filterPaymentsByFrequency(credits, 'M')),
    };
  });

  return totals;
});

const grandTotals = computed(() => {
  const totals: CCLoanStats = {
    count: 0,
    limiteCredito: 0,
    creditoMaximo: 0,
    saldoActual: 0,
    saldoVencido: 0,
    pagoSemanal: 0,
    pagoQuincenal: 0,
    pagoMensual: 0,
  };

  Object.values(totalsByType.value).forEach((group) => {
    totals.count += group.count;
    totals.limiteCredito += group.limiteCredito;
    totals.creditoMaximo += group.creditoMaximo;
    totals.saldoActual += group.saldoActual;
    totals.saldoVencido += group.saldoVencido;
    totals.pagoSemanal += group.pagoSemanal;
    totals.pagoQuincenal += group.pagoQuincenal;
    totals.pagoMensual += group.pagoMensual;
  });

  return totals;
});

let creditStatusChart;
let debtAnalysisChart;

onMounted(() => {
  const canvas1 = document.getElementById('creditStatusChart');
  const canvas2 = document.getElementById('debtAnalysisChart');

  if (canvas1 instanceof HTMLCanvasElement && canvas2 instanceof HTMLCanvasElement) {
    const ctx1 = canvas1.getContext('2d');
    const ctx2 = canvas2.getContext('2d');

    if (ctx1 && ctx2) {
      creditStatusChart = new Chart(ctx1, {
        type: 'pie',
        data: creditStatusData,
      });

      debtAnalysisChart = new Chart(ctx2, {
        type: 'pie',
        data: debtAnalysisData,
      });
    }
  }
});

const containerRef = ref<HTMLElement | null>(null);

async function downloadAsPDF() {
  if (containerRef.value) {
    htmlToPdf(containerRef.value);
  }
}
</script>

<template>
  <div class="flex flex-col py-2">
    <button
      v-if="containerRef"
      class="flex items-center justify-center self-end rounded-lg bg-primary-600 p-2"
      @click="() => downloadAsPDF()"
    >
      <ArrowDownOnSquareIcon class="size-5 text-white" />
    </button>
    <div
      ref="containerRef"
      class="flex flex-col gap-y-10"
    >
      <div class="flex flex-col gap-y-2">
        <h2 class="text-xl font-semibold">
          Fico score
        </h2>
        <div
          v-if="ficoScore"
          class="flex items-center justify-center self-start rounded-xl bg-gray-400 p-2"
        >
          <p class="whitespace-nowrap text-white">
            <span class="text-xl font-bold">
              {{ ficoScore }}
            </span>
            <span class="text-xs font-medium">
              / 850
            </span>
          </p>
        </div>
        <p
          v-else
          class="italic text-gray-600"
        >
          No hay fico score
        </p>
      </div>
      <div class="flex flex-col gap-y-2">
        <h2 class="text-xl font-semibold">
          Datos generales
        </h2>
        <div class="grid w-auto grid-cols-2 gap-2 self-start">
          <div class="flex flex-col gap-y-1 text-right font-semibold">
            <h3>Nombre(s):</h3>
            <h3>Apellido paterno:</h3>
            <h3>Apellido materno:</h3>
            <h3>Fecha nacimiento:</h3>
            <h3>RFC:</h3>
          </div>
          <div class="flex flex-col gap-y-1">
            <p>{{ consolidatedCreditReport.persona.nombres }}</p>
            <p>{{ consolidatedCreditReport.persona.apellidoPaterno }}</p>
            <p>{{ consolidatedCreditReport.persona.apellidoMaterno }}</p>
            <p>{{ consolidatedCreditReport.persona.fechaNacimiento }}</p>
            <p>{{ consolidatedCreditReport.persona.rFC }}</p>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-y-2">
        <h2 class="text-xl font-semibold">
          Domicilios
        </h2>
        <table class="min-w-full bg-white">
          <thead>
            <tr>
              <th>Calle y numero</th>
              <th>Colonia</th>
              <th>Del/Mpio</th>
              <th>Ciudad</th>
              <th>Estado</th>
              <th>CP</th>
              <th>Fecha de</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(domicilio, index) in consolidatedCreditReport.domicilios"
              :key="index"
            >
              <td>{{ domicilio.direccion }}</td>
              <td>{{ domicilio.coloniaPoblacion }}</td>
              <td>{{ domicilio.delegacionMunicipio }}</td>
              <td>{{ domicilio.ciudad }}</td>
              <td>{{ domicilio.estado }}</td>
              <td>{{ domicilio.cP }}</td>
              <td>{{ domicilio.fechaRegistroDomicilio }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex flex-col gap-y-2">
        <h2 class="text-xl font-semibold">
          Resumen por producto
        </h2>
        <table
          v-if="consolidatedCreditReport.creditos"
          class="min-w-full border-collapse border border-gray-400 bg-white"
        >
          <thead>
            <tr>
              <th class="border border-gray-300 px-4 py-2">
                Producto
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Cuentas
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Limite
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Aprobado
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Actual
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Vencido
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Pago semanal
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Pago Quincenal
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Pago Mensual
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(group, tipoCredito) in totalsByType"
              :key="tipoCredito"
            >
              <td class="border border-gray-300 px-4 py-2">
                {{ tipoCredito }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ group.count }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(group.limiteCredito) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(group.creditoMaximo) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(group.saldoActual) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(group.saldoVencido) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(group.pagoSemanal) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(group.pagoQuincenal) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(group.pagoMensual) }}
              </td>
            </tr>
            <tr class="font-bold">
              <td class="border border-gray-300 px-4 py-2">
                Totales
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ grandTotals.count }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(grandTotals.limiteCredito) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(grandTotals.creditoMaximo) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(grandTotals.saldoActual) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(grandTotals.saldoVencido) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(grandTotals.pagoSemanal) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(grandTotals.pagoQuincenal) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(grandTotals.pagoMensual) }}
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-else
          class="text-xl text-gray-600"
        >
          No hay créditos
        </p>
      </div>
      <div class="flex flex-col gap-y-2">
        <h2 class="text-xl font-semibold">
          Indicadores
        </h2>
        <div
          v-if="consolidatedCreditReport.creditos"
          class="grid grid-cols-3 gap-4"
        >
          <div>
            <canvas id="creditStatusChart" />
          </div>
          <div>
            <canvas id="debtAnalysisChart" />
          </div>
          <div class="flex flex-col justify-center">
            <p>
              Créditos Abiertos en el Presente Año =
              <span class="font-bold">
                {{ activeCredits.length }}
              </span>
            </p>
            <p>
              Crédito más antiguo =
              <span class="font-bold">
                {{ consolidatedCreditReport.creditos.length ?
                  consolidatedCreditReport.creditos[0].fechaAperturaCuenta :
                  'N/A' }}
              </span>
            </p>
            <p>
              Monto mayor aprobado =
              <span class="font-bold">
                {{ toCurrency(totalApproved) }}
              </span>
            </p>
          </div>
        </div>
        <p
          v-else
          class="text-xl text-gray-600"
        >
          No hay créditos
        </p>
      </div>
      <div class="flex flex-col gap-y-2">
        <h2 class="text-xl font-semibold">
          Detalles de Créditos
        </h2>
        <Disclosure
          v-slot="{ open: disclosureOpen }"
          default-open
        >
          <DisclosureButton
            class="flex flex-col gap-x-2 self-start rounded-lg font-semibold"
          >
            <div class="flex items-center gap-x-1">
              <p>Créditos grupales</p>
              <p
                v-if="!!reportRecentGroupLoansDelinquent.amount"
                class="font-semibold text-red-600"
              >
                ({{ toCurrency(reportRecentGroupLoansDelinquent.amount) }} de
                {{ reportRecentGroupLoansDelinquent.numOfLoans }} créditos)
              </p>
              <ChevronUpIcon
                :class="{'rotate-180': !disclosureOpen}"
                class="size-5 text-primary-900"
              />
            </div>
            <p
              v-if="!disclosureOpen"
              class="text-xs font-normal text-gray-500"
            >
              Haz click aquí para desplegar la información
            </p>
          </DisclosureButton>
          <DisclosurePanel>
            <consolidated-credit-report-loans-table
              :loans="groupMemberLoanApplication.groupLoans"
              :recent-loans="groupMemberLoanApplication.recentGroupLoans"
            />
          </DisclosurePanel>
        </Disclosure>
        <Disclosure
          v-slot="{ open: disclosureOpen }"
          default-open
        >
          <DisclosureButton
            class="flex flex-col gap-x-2 self-start rounded-lg font-semibold"
          >
            <div class="flex items-center gap-x-1">
              <p>Créditos individuales</p>
              <p
                v-if="reportRecentIndividualLoansDelinquent.amount > 0"
                class="font-semibold text-red-600"
              >
                ({{ toCurrency(reportRecentIndividualLoansDelinquent.amount) }} de
                {{ reportRecentIndividualLoansDelinquent.numOfLoans }} créditos)
              </p>
              <ChevronUpIcon
                :class="{'rotate-180': !disclosureOpen}"
                class="size-5 text-primary-900"
              />
            </div>
            <p
              v-if="!disclosureOpen"
              class="text-xs font-normal text-gray-500"
            >
              Haz click aquí para desplegar la información
            </p>
          </DisclosureButton>
          <DisclosurePanel>
            <consolidated-credit-report-loans-table
              :loans="groupMemberLoanApplication.individualLoans"
              :recent-loans="groupMemberLoanApplication.recentIndividualLoans"
            />
          </DisclosurePanel>
        </Disclosure>
        <p
          v-if="!groupMemberLoanApplication.groupLoans.length && !groupMemberLoanApplication.individualLoans.length"
          class="text-xl text-gray-600"
        >
          No hay créditos
        </p>
      </div>
      <div class="flex flex-col gap-y-2">
        <h2 class="font-bold">
          Consultas Realizadas
        </h2>
        <table class="min-w-full border-collapse border border-gray-400 bg-white">
          <thead>
            <tr>
              <th class="border border-gray-300 px-4 py-2">
                Fecha de Consulta
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Otorgante
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Tipo de Crédito
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Monto
              </th>
              <th class="border border-gray-300 px-4 py-2">
                Moneda
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(consulta, index) in consolidatedCreditReport.consultas"
              :key="index"
            >
              <td class="border border-gray-300 px-4 py-2 uppercase">
                {{ formatDate(consulta.fechaConsulta, 'dd / MMM / yy') }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ consulta.nombreOtorgante }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{
                  $te(`circuloCreditReport.loanType.${consulta.tipoCredito}`) ?
                    $t(`circuloCreditReport.loanType.${consulta.tipoCredito}`) :
                    consulta.tipoCredito
                }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ toCurrency(consulta.importeCredito) }}
              </td>
              <td class="border border-gray-300 px-4 py-2">
                {{ consulta.claveUnidadMonetaria }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

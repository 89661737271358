<script setup lang="ts">
import { ClockIcon } from '@heroicons/vue/24/outline';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid';
import { formatDistanceToNow } from 'date-fns';
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import type { GroupMemberLoanApplication } from '@/api/groupMemberLoanApplication';
import { useRejectKycMutation } from '@/api/groupMemberLoanApplication/kycRejection/mutations';
import { useFetchKycDocuments } from '@/api/kycDocument/queries';
import BaseButton from '@/components/base-button.vue';
import BaseHeaderTable from '@/components/base-header-table.vue';
import BaseSpinner from '@/components/base-spinner.vue';
import BaseTabs from '@/components/base-tabs.vue';
import GroupLoanApplicationsMemberLoanKycReviewSection
  from '@/components/reviews/group-loan-applications-member-loan-kyc-review-section.vue';
import useUIStore from '@/stores/ui';

interface Props {
  groupMemberLoanApplication: GroupMemberLoanApplication
}

const props = defineProps<Props>();

const { t } = useI18n();
const uiStore = useUIStore();

const documentableId = computed(() => props.groupMemberLoanApplication.id);

const kycDocumentsQ = useFetchKycDocuments({ documentableId, documentableType: 'GroupMemberLoanApplication' });

const kycDocuments = computed(() => kycDocumentsQ.data || []);

const allDocumentsReviewed = computed(() => (
  kycDocuments.value.every((kycDocument) => kycDocument.aasmState !== 'review_pending')
));
const someDocumentsRejected = computed(() => (
  kycDocuments.value.some((kycDocument) => kycDocument.aasmState === 'rejected')
));
const bannerText = computed(() => {
  if (!allDocumentsReviewed.value) return '';

  if (someDocumentsRejected.value) {
    return 'Todos los documentos han sido revisados, pero algunos han sido rechazados.';
  }

  return 'Todos los documentos de este miembro han sido aprobados.';
});
const groupMemberLoanApplicationId = computed(() => props.groupMemberLoanApplication.id);
const kycIsRejected = reactive<{ [key: number]: boolean }>({});
const rejectKycMutation = useRejectKycMutation({
  groupMemberLoanApplicationId,
  onSuccess: () => {
    uiStore.toast({
      message: 'KYC enviado a corregir',
      type: 'success',
      position: 'top',
    });
    kycIsRejected[props.groupMemberLoanApplication.id] = true;
  },
});

const tabs = computed(() => (
  // eslint-disable-next-line complexity
  kycDocuments.value.map((kycDocument) => (
    {
      title: t(`kycDocument.kinds.${kycDocument.kind}`),
      icon: (kycDocument.aasmState === 'approved' && CheckCircleIcon) ||
        (kycDocument.aasmState === 'rejected' && XCircleIcon) ||
      undefined,
      iconClass: (kycDocument.aasmState === 'rejected' && 'text-red-500') || undefined,
    }
  ))
));
const selectedTabIndex = ref(0);

</script>

<template>
  <div class="flex w-full flex-col gap-y-4 px-2 py-4">
    <template v-if="groupMemberLoanApplication.kycSubmittedAt">
      <div class="flex items-center gap-x-1">
        <ClockIcon
          class="size-4 text-primary-600/70"
        />
        <p class="text-sm text-gray-700">
          KYC enviado
          {{ formatDistanceToNow(new Date(groupMemberLoanApplication.kycSubmittedAt), { addSuffix: true }) }}
        </p>
      </div>
      <base-header-table
        size="sm"
        title="Referencias"
        :headers="['Nombre', 'Teléfono', 'Relación']"
        :rows="groupMemberLoanApplication.references.map((reference) => [
          reference.name ?? '',
          reference.phoneNumber ?? '',
          reference.relationship ?? '',
        ])"
      />
      <base-spinner
        v-if="kycDocumentsQ.isPending"
        class="size-8 self-center"
      />
      <div
        v-else-if="allDocumentsReviewed"
        class="flex items-center justify-center gap-x-4 rounded-lg bg-primary-100 p-4 text-primary-700"
      >
        <p>{{ bannerText }}</p>
        <base-button
          v-if="someDocumentsRejected"
          text="Enviar a corregir"
          variant="primary"
          size="sm"
          :loading="rejectKycMutation.isPending"
          :disabled="kycIsRejected[props.groupMemberLoanApplication.id]"
          @click="rejectKycMutation.mutate()"
        />
      </div>
      <base-tabs
        :tabs="tabs"
        :selected-tab-index="selectedTabIndex"
      >
        <template
          v-for="(kycDocument, index) in kycDocuments"
          :key="kycDocument.kind"
          #[`tab-${index}`]
        >
          <group-loan-applications-member-loan-kyc-review-section
            :kyc-document="kycDocument"
            :group-member-loan-application="groupMemberLoanApplication"
          />
        </template>
      </base-tabs>
    </template>
    <template v-else>
      <p class="py-16 text-center text-lg">
        Aún no se ha enviado el formulario de KYC
      </p>
    </template>
  </div>
</template>

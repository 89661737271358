import { api } from '@/api/index';
import addToFormData from '@/utils/addToFormData';

interface BaseKycDocument {
  id: number;
  kind: 'front_id_card' | 'back_id_card' | 'selfie_id_card' | 'address_proof';
  aasmState: 'review_pending' | 'approved' | 'rejected';
  fileUrl: string;
}

interface FrontIdCard extends BaseKycDocument {}

interface BackIdCard extends BaseKycDocument {}

interface SelfieIdCard extends BaseKycDocument {}

interface AddressProof extends BaseKycDocument {
  postalCode: string;
  emittedBy: string;
  emittedOn: string;
  street: string;
  externalNumber: string;
  internalNumber: string;
  colony: string;
  municipality: string;
  city: string;
  state: string;
  documentableId: number;
}

type KycDocument = FrontIdCard | BackIdCard | SelfieIdCard | AddressProof;

interface RejectionTags {
  front_id_card: string[];
  back_id_card: string[];
  selfie_id_card: string[];
  address_proof: string[];
}

const basePath = '/api/internal/kyc_documents';

interface IndexParams {
  documentableId: number
  documentableType: string
}
function index({ documentableId, documentableType }: IndexParams) {
  return api.get<{ kycDocuments: KycDocument[] }>(
    basePath,
    { params: { documentableId, documentableType } },
  );
}

type AasmStateEvent = 'pass_to_review' | 'approve' | 'reject';

interface BaseUpdatePayload {
  aasmStateEvent: AasmStateEvent;
}

function update<TPayload = BaseUpdatePayload>(id: number, body: TPayload) {
  const formData = new FormData();
  addToFormData(formData, id);
  addToFormData(formData, body);

  return api.patch<{ kycDocument: KycDocument }>(
    `${basePath}/${id}`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  );
}

export type {
  KycDocument,
  FrontIdCard,
  BackIdCard,
  SelfieIdCard,
  AddressProof,
  BaseUpdatePayload,
  AasmStateEvent,
  RejectionTags,
};

export { index, update };

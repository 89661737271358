<script setup lang="ts">
import { onBeforeUnmount, onMounted, provide, ref } from 'vue';

import type { GroupConnections } from '@/api/group';
import { type CompositionChanges, type GroupLoanApplication, type ConsolidatedLoan } from '@/api/groupLoanApplication';
import {
  useFetchActionLogs as useFetchGroupLoanApplicationActionLogs,
} from '@/api/groupLoanApplication/actionLog/queries';
import { useFetchGroupLoanApplication } from '@/api/groupLoanApplication/queries';
import type { RejectionTags } from '@/api/kycDocument';
import { type PromoterPresidentInvite } from '@/api/promoterPresidentInvite';
import ActionLogs from '@/components/action-logs.vue';
import BaseTabs from '@/components/base-tabs.vue';
import Comments from '@/components/comments.vue';
import GroupLoanApplicationHeader from '@/components/reviews/group-loan-application-header.vue';
import GroupLoanApplicationMembersSection from '@/components/reviews/group-loan-application-members-section.vue';
import LoanApplicationPreApprovalSlideOver from
  '@/components/reviews/group-loan-application-pre-approval-slide-over.vue';
import { kycDocumentsRejectionTagsKey } from '@/utils/keys';

interface Props {
  groupLoanApplication: GroupLoanApplication;
  consolidatedLoans: ConsolidatedLoan[];
  previousGroupLoanApplications: GroupLoanApplication[];
  groupConnections: GroupConnections;
  promoterPresidentInvite?: PromoterPresidentInvite;
  groupLoanApplicationRejectionTags: string[];
  groupMemberLoanApplicationRejectionTags: string[];
  kycDocumentsRejectionTags: RejectionTags;
  compositionChanges: CompositionChanges;
}

const props = defineProps<Props>();

provide(kycDocumentsRejectionTagsKey, props.kycDocumentsRejectionTags);

const groupLoanApplicationQ = useFetchGroupLoanApplication({
  id: props.groupLoanApplication.id,
  initialData: props.groupLoanApplication,
});

const preApprovalSlideOverOpen = ref(false);

function handleKeydown(event: KeyboardEvent) {
  if (event.shiftKey && event.key === 'M') {
    preApprovalSlideOverOpen.value = !preApprovalSlideOverOpen.value;
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeydown);
});

onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown);
});

const selectedTabIndex = ref(0);
const actionLogsQ = useFetchGroupLoanApplicationActionLogs({ groupLoanApplicationId: props.groupLoanApplication.id });
</script>
<template>
  <div
    v-if="groupLoanApplicationQ.data"
    ref="containerRef"
    class="flex w-full flex-col gap-y-4"
  >
    <div class="flex flex-row gap-x-4 rounded-lg bg-gray-100 p-4">
      <div class="flex w-full flex-col gap-10 md:flex-row">
        <group-loan-application-header
          :group-loan-application="groupLoanApplicationQ.data"
          :previous-group-loan-applications="previousGroupLoanApplications"
          :rejection-tags="groupLoanApplicationRejectionTags"
          :group-connections="groupConnections"
          :promoter-president-invite="promoterPresidentInvite"
          :consolidated-loans="consolidatedLoans"
          :composition-changes="compositionChanges"
        />
        <div class="w-auto sm:w-[350px] md:ml-auto xl:w-[450px]">
          <base-tabs
            :selected-tab-index="selectedTabIndex"
            :tabs="[
              { title: 'Comentarios' },
              { title: 'Historial de acciones' },
            ]"
          >
            <template #tab-0>
              <comments
                name="comment[body]"
                resource-type="GroupLoanApplication"
                :resource-id="groupLoanApplicationQ.data.id"
              />
            </template>
            <template #tab-1>
              <div class="max-h-[600px] overflow-y-scroll rounded-lg bg-white p-2">
                <action-logs :action-logs="actionLogsQ.data || []" />
              </div>
            </template>
          </base-tabs>
        </div>
      </div>
    </div>
    <group-loan-application-members-section
      v-if="groupLoanApplicationQ.data"
      :group-loan-application="groupLoanApplicationQ.data"
    />
    <div class="flex flex-col gap-x-4 gap-y-2 md:flex-row">
      <div class="flex w-full flex-col gap-y-4 md:w-56 md:shrink-0" />
    </div>
    <loan-application-pre-approval-slide-over
      :group-loan-application="groupLoanApplication"
      :open="preApprovalSlideOverOpen"
      :rejection-tags="groupMemberLoanApplicationRejectionTags"
      @close="preApprovalSlideOverOpen = false"
    />
  </div>
</template>

<script setup lang="ts">
import { useQueryClient } from '@tanstack/vue-query';
import { pascalize } from 'humps';
import { useForm } from 'vee-validate';
import { toRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { mixed, object } from 'yup';

import type { KycDocument } from '@/api/kycDocument';
import { useUpdateKycDocument } from '@/api/kycDocument/mutations';
import { groupLoanApplicationKey } from '@/api/queryKeys';
import { tagsKey } from '@/api/queryKeys/tags';
import type { ResourceType } from '@/api/tags';
import BaseModal from '@/components/base-modal.vue';
import VeeValidateFileInput from '@/components/vee-validate/vee-validate-file-input.vue';

const { t } = useI18n();

interface Props {
  kycDocument: KycDocument;
  documentableId: number;
  documentableType: string;
  groupLoanApplicationId: number;
  show: boolean;
}
const props = defineProps<Props>();

interface Emits {
  (e: 'close'): void;
}
const emit = defineEmits<Emits>();

const queryClient = useQueryClient();
const updateKycDocumentMutation = useUpdateKycDocument<{ file: File }>({
  documentableId: toRef(props, 'documentableId'),
  documentableType: toRef(props, 'documentableType'),
  onSuccess: () => {
    queryClient.invalidateQueries({ queryKey: groupLoanApplicationKey(toRef(props, 'groupLoanApplicationId')) });
    queryClient.invalidateQueries({
      queryKey: tagsKey({
        resourceType: toRef(pascalize(props.kycDocument.kind) as ResourceType),
        resourceId: toRef(props.kycDocument, 'id'),
        context: toRef<'rejection_tags'>('rejection_tags'),
      }),
    });
    emit('close');
  },
});

const validationSchema = object({
  file: mixed<File>().required('El archivo es requerido'),
});

const { handleSubmit } = useForm<{ file: File }>({ validationSchema });

const onSubmit = handleSubmit((values) => {
  updateKycDocumentMutation.mutate({
    id: props.kycDocument.id,
    body: {
      kycDocumentType: props.kycDocument.kind,
      ...values,
    },
  });
});

</script>

<template>
  <base-modal
    :show="show"
    hide-actions
    @close="emit('close')"
  >
    <template #body>
      <form
        class="flex flex-col gap-y-4"
        @submit="onSubmit"
      >
        <vee-validate-file-input
          input-id="kyc-document-file"
          name="file"
          :label="t(`kycDocument.kinds.${kycDocument.kind}`)"
          extensions=".JPG, .JPEG, .PNG"
          initial-file-name="INE (frontal)"
        />

        <div class="flex items-center gap-x-4">
          <base-button
            class="flex-1"
            variant="white"
            text="Volver"
            @click="emit('close')"
          />
          <base-button
            type="submit"
            class="flex-1"
            text="Subir documento"
            :loading="updateKycDocumentMutation.isPending"
          />
        </div>
      </form>
    </template>
  </base-modal>
</template>
